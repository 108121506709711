<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        Detail Release Point
        <div class="card-header-actions">
          <a href="release-point" class="card-header-action" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <br>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row class="mb-4">
          <b-col sm="4">
            <h6>User</h6>
            <div class="mb-4"><strong>{{this.name}} ({{ this.email }})</strong></div>
            <h6>Invoice No</h6>
            <div class="mb-4"><strong>{{this.invoice}}</strong></div>
            <h6>Release Date</h6>
            <div class="mb-4"><strong>{{this.releaseDate}}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Kode Referral</h6>
            <div class="mb-4"><strong>{{this.referral}}</strong></div>
            <h6>Benefit</h6>
            <div class="mb-4"><strong>{{this.benefit}}</strong></div>
            <h6>Rejection Reason</h6>
            <div class="mb-4"><strong>{{this.reason}}</strong></div>
          </b-col>
          <b-col sm="4">
            <h6>Transaction Date</h6>
            <div class="mb-4"><strong>{{this.date}}</strong></div>
            <h6>Status</h6>
            <div class="mb-4"><strong>{{this.status}}</strong></div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <br>
    <b-card no-body>
      <b-card-header>
        History
      </b-card-header>
      <br>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <div class="table-responsive-sm">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Transaction Date</th>
                <th>Nomor Invoice</th>
                <th>Benefit</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(items, index) in history" :key="index">
                <td>{{items.transaction_date}}</td>
                <td>{{items.invoice_number}}</td>
                <td>{{items.benefit}}</td>
                <td>{{items.status}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'detail-release-point',
  data() {
    return {
      name: '',
      email: '',
      invoice: '',
      releaseDate: '',
      referral: '',
      benefit: '',
      reason: '',
      date: '',
      status: '',
      history: [],
      isLoading: false,
      errors: {
        code: '',
        status: '',
        message: ''
      }
    }
  },
  created() {
    this.$http.get(`army/release-point/detail?id=` + this.$route.params.id)
    .then((result) => {
      const payload = result.data.data;
      this.name = payload.detail.users.name;
      this.email = payload.detail.users.email;
      this.invoice = payload.detail.invoice_number;
      this.releaseDate = payload.detail.release_date
      this.referral = payload.detail.referral_code;
      this.benefit = payload.detail.benefit;
      this.reason = payload.detail.reason;
      this.date = payload.detail.transaction_date;
      this.status = payload.detail.status;
      this.history = payload.histories;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.status;
        this.errors.message = error.response.message;
        this.errors.status = error.response.data.meta.code;
      }
    })
  }
}
</script>